import { render, staticRenderFns } from "./sensitiveXq.vue?vue&type=template&id=59f59c35&scoped=true"
import script from "./sensitiveXq.vue?vue&type=script&lang=js"
export * from "./sensitiveXq.vue?vue&type=script&lang=js"
import style0 from "./sensitiveXq.vue?vue&type=style&index=0&id=59f59c35&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59f59c35",
  null
  
)

export default component.exports